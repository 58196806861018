<template>
  <Transition>
    <Page v-if="state === 'name'" class="default-page-anim">
      <SAM
        :data="nameSam"
        class="page-content-anim page-content-margin"
        gradient
      />
      <TextInput
        class="page-content-margin"
        :inputLabel="nameLabel"
        :submitLabel="nameSubmitLabel"
        autocomplete="name"
        maxlength="40"
        trim
        @next="nameSubmit"
      />
    </Page>
    <Page v-else-if="state === 'age'" class="default-page-anim">
      <SAM
        :data="ageSam"
        class="page-content-anim page-content-margin"
        gradient
      />
      <Dialogue :options="ageOptions" @choice="ageChosen" gradient />
    </Page>
    <Page v-else-if="state === 'gender'" class="default-page-anim">
      <SAM
        :data="genderSam"
        class="page-content-anim page-content-margin"
        gradient
      />
      <Dialogue :options="genderOptions" @choice="genderChosen" gradient />
    </Page>
    <Page v-else-if="state === 'confirm'" class="default-page-anim">
      <SAM
        :data="confirmSam"
        class="page-content-anim page-content-margin"
        gradient
      />
      <ol class="page-content-margin profile-create__profile">
        <li v-for="(value, i) in profile" class="profile-create__item">
          <div
            class="profile-create__icon"
            :class="`profile-create__icon--${i + 1}`"
            aria-hidden="true"
          >
            <div class="profile-create__mult">
              <svg
                viewBox="0 0 100 100"
                class="profile-create__svg"
                :class="`profile-create__svg--${i + 1}`"
              >
                <polygon
                  stroke-width="12"
                  stroke-linejoin="round"
                  stroke="#FFF"
                  points="88.99,62.67 19.53,77.43 41.48,9.90"
                />
              </svg>
            </div>
          </div>
          <div>{{ value }}</div>
        </li>
      </ol>
      <Dialogue :options="confirmOptions" @choice="confirmChosen" gradient />
    </Page>
  </Transition>
</template>

<script>
import Page from "@/components/pages/Page.vue";
import SAM from "@/components/content/SAM.vue";
import Dialogue from "@/components/content/Dialogue.vue";
import TextInput from "@/components/content/TextInput.vue";

import { API } from "@aws-amplify/api";
import UserData from "@/scripts/userData.js";
import * as Data from "@/scripts/data.js";

export default {
  emits: ["success", "error"],
  components: {
    Page,
    SAM,
    Dialogue,
    TextInput,
  },
  data() {
    const {
      data: {
        account: {
          profiles: {
            profile_maker: {
              header: headerTitle,
              name: {
                prompt: nameSam,
                input_label: nameLabel,
                submit_label: nameSubmitLabel,
              },
              age: { prompt: ageSam, options: _ageOptions },
              gender: {
                prompt: genderSam,
                options: { male, female, other },
              },
              confirm: {
                prompt: confirmSam,
                options: { yes: confirmYes, no: confirmNo },
              },
            },
          },
        },
      },
    } = Data;

    return {
      state: "name",

      profile: [],
      apiData: {},

      headerTitle,

      // name
      // nameSam: "Add a profile for the person playing",
      nameSam,
      nameLabel,
      nameSubmitLabel,

      // age
      ageSam,
      ageOptions: [
        _ageOptions["lt_10"],
        _ageOptions["10-14"],
        _ageOptions["15-17"],
        _ageOptions["ge_18"],
      ],

      // gender
      genderSam,
      genderOptions: [male, female, other],

      // confirm
      confirmSam,
      confirmOptions: [confirmYes, confirmNo],
    };
  },
  methods: {
    nameSubmit(name) {
      name = name.trim();

      if (name) {
        this.apiData.name = name;
        this.profile[0] = name;
        this.state = "age";
        this.$root.setHeaderProgress(1, 3);
      }
    },
    ageChosen(choice) {
      this.apiData.age = ["<10", "10-14", "15-17", "18+"][choice];
      this.profile[1] = this.ageOptions[choice];
      this.state = "gender";
      this.$root.setHeaderProgress(2, 3);
    },
    genderChosen(choice) {
      this.apiData.gender = ["Male", "Female", "Other"][choice];
      this.profile[2] = this.genderOptions[choice];
      this.state = "confirm";
      this.$root.setHeaderProgress(3, 3);
    },
    confirmChosen(choice) {
      if (choice === 0) {
        API.post("AppAPI", "/app/profile", {
          body: {
            profile: this.apiData,
          },
        }).then(this.onSuccess, this.onError);
        this.$root.setHeaderDisplay(false);
        this.state = null;
      } else {
        this.$root.setHeaderProgress(0, 3);
        this.state = "name";
      }
    },
    onSuccess(remoteData) {
      UserData.pullData(remoteData);
      this.$emit("success");
    },
    onError() {
      this.$emit("error");
    },
  },
  mounted() {
    this.$root.setHeaderTitle(this.headerTitle);
    this.$root.setHeaderProgressStyle("dot");
    this.$root.setHeaderDisplay(true);
    this.$root.setHeaderProgress(0, 3);
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/generate-gradient.scss";

@keyframes profile-create-icon-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.profile-create {
  // profile card used in the confirmation screen
  &__profile {
    list-style-type: none;
    padding: 2rem;
    border-radius: $fragment-radius;
    box-shadow: 0 0.5rem 2rem 0.5rem rgba(239, 154, 67, 0.8);
  }

  &__item {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    font-weight: $medium;
    letter-spacing: 0.05em;
    line-height: 1.25;

    &:first-child {
      margin-top: 0;
    }
  }

  &__icon {
    margin-right: 1.5rem;
    background-size: 150% 100%;
    background-position: right center;

    $blocks: 3;
    $colors: ($swipesafe-orange $swipesafe-yellow $swipesafe-orange),
      ($swipesafe-orange $swipesafe-magenta),
      ($swipesafe-blue $swipesafe-yellow $swipesafe-green),
      ($swipesafe-yellow $swipesafe-green $swipesafe-red),
      ($swipesafe-aqua $swipesafe-red $swipesafe-blue);
    $rotations: (-80deg -10deg 90deg -100deg -80deg);

    @for $i from 1 through $blocks {
      $color: nth($colors, $i);
      $rotation: nth($rotations, $i);

      &--#{$i} {
        @include generate-gradient($color, $rotation);
      }
    }

    animation: profile-create-icon-rotate 9s -1s linear infinite;

    &--2 {
      animation-delay: -2s;
    }

    &--3 {
      animation-delay: -3s;
    }
  }

  &__mult {
    width: 1.625rem;
    height: 1.625rem;
    background-color: #000;
    mix-blend-mode: multiply;
  }

  &__svg {
    display: block;
    width: 1.625rem;
    height: 1.625rem;
  }
}
</style>
