<template>
  <div
    class="wrapper"
    :class="{
      'wrapper--hide': modalOpen,
    }"
  >
    <TheHeader ref="header" />

    <SwipeActivityPrompt ref="swipeActivityPrompt" v-if="state === 'chapter'" />

    <div
      v-if="doTransition"
      class="content"
      :class="{
        'content--show': !menuOpen,
      }"
    >
      <Transition>
        <Startup
          v-if="state === 'startup' && isLangReady"
          :authReady="authReady"
          :loggedIn="loggedIn"
          @next="afterStartup"
        />
        <OfflineStatus v-else-if="state === 'offline' && isLangReady" />
        <Splash
          v-else-if="state === 'splash'"
          ref="splash"
          @next="afterSplash"
        />
        <LoginWrapper
          v-else-if="state === 'login'"
          :guestId="guestId"
          @afterLogin="afterLogin"
          @changeLanguage="changeLanguage"
          @startGuestMode="startGuestMode"
        />
        <ProfileSelect
          v-else-if="state === 'profile'"
          @setProfile="setProfile"
          @logout="profileLogout"
        />
        <Intro v-else-if="state === 'intro'" @fin="toFirstConcept" />
        <FirstConcept
          v-else-if="state === 'first-concept'"
          @fin="toSafetyPlan"
        />
        <CreateSafetyPlan
          v-else-if="state === 'safety-plan'"
          @toInbox="toInbox"
          @toKeyConcepts="toKeyConcepts"
        />
        <Return v-else-if="state === 'return'" @next="toKeyConcepts" />
        <UpdateSuccess v-else-if="state === 'updated'" @next="toFork" />
        <PlayerFeedback v-else-if="state === 'feedback'" @next="toFork" />
        <Fork
          v-else-if="state === 'fork'"
          @toInbox="toInbox"
          @toKeyConcepts="toKeyConcepts"
        />
        <Inbox v-else-if="state === 'inbox'" @toChapter="toChapter" />
        <Chapter
          v-else-if="state === 'chapter'"
          :chapter="chapter"
          @next="afterChapter"
          @toInbox="toInbox"
        />
        <KeyConcepts v-else-if="state === 'concepts'" @next="toFork" />
        <Outro v-else-if="state === 'outro'" @fin="toFork" />
      </Transition>

      <Shapes v-if="isAfterLogin" ref="shapes" />
    </div>

    <NavPrompt
      v-if="isControlsReady"
      ref="prompt"
      :menuOpen="menuOpen"
      :modalOpen="modalOpen"
    />

    <MenuIcon
      v-if="isControlsReady"
      ref="icon"
      :menuOpen="menuOpen"
      :pulse="menuPulse"
      :show="menuIconShow"
      @toggle="toggleMenu"
    />

    <MuteButton v-if="isControlsReady" :show="isMuteButtonShown" />

    <Transition name="menu">
      <Menu v-if="menuOpen" ref="menu" :updateReady="updateReady" />
    </Transition>
  </div>
</template>

<script>
import "@/assets/styles/main.scss";

import { Auth } from "@aws-amplify/auth";
import { API } from "@aws-amplify/api";

import * as AudioManager from "@/scripts/audioManager.js";
import * as Background from "@/scripts/background.js";
import * as Insert from "@/scripts/insert.js";
import * as Input from "@/scripts/input.js";
import * as Data from "@/scripts/data.js";
import * as ServiceWorker from "@/registerServiceWorker.js";
import * as Scroll from "@/scripts/scroll.js";
import PersistentData from "@/scripts/persistentData.js";
import UserData from "@/scripts/userData.js";
import UserApi from "@/scripts/userApi.js";
import OfflineMedia from "@/scripts/offlineMedia.js";
import SessionData from "@/scripts/sessionData.js";
import * as Connection from "@/scripts/connection.js";
import * as Analytics from "@/scripts/analytics.js";
import { defaultGradient } from "@/scripts/globals.js";
import {
  allChaptersComplete,
  testCompletionCount,
  firstIncompleteChapter,
} from "@/scripts/helpers.js";

import TheHeader from "@/components/header/TheHeader.vue";
import Shapes from "@/components/Shapes.vue";
import NavPrompt from "@/components/NavPrompt.vue";
import SwipeActivityPrompt from "@/components/SwipeActivityPrompt.vue";
import MenuIcon from "@/components/MenuIcon.vue";
import Menu from "@/components/sections/menu/Menu.vue";
import MuteButton from "@/components/MuteButton.vue";
import Startup from "@/components/sections/Startup.vue";
import OfflineStatus from "@/components/sections/OfflineStatus.vue";
import Splash from "@/components/sections/Splash.vue";
import LoginWrapper from "@/components/sections/login/LoginWrapper.vue";
import ProfileSelect from "@/components/sections/profiles/ProfileSelect.vue";
import Intro from "@/components/sections/Intro.vue";
import FirstConcept from "@/components/sections/FirstConcept.vue";
import CreateSafetyPlan from "@/components/sections/CreateSafetyPlan.vue";
import Return from "@/components/sections/Return.vue";
import UpdateSuccess from "@/components/sections/UpdateSuccess.vue";
import PlayerFeedback from "@/components/sections/PlayerFeedback.vue";
import Fork from "@/components/sections/Fork.vue";
import Inbox from "@/components/sections/Inbox.vue";
import Chapter from "@/components/sections/chapter/Chapter.vue";
import KeyConcepts from "@/components/sections/KeyConcepts.vue";
import Outro from "@/components/sections/Outro.vue";

const MENU_DELAY = 1000;
const GUEST_POLL_INTERVAL = 10000;

export default {
  components: {
    Shapes,
    Menu,
    MenuIcon,
    MuteButton,
    NavPrompt,
    SwipeActivityPrompt,
    Startup,
    OfflineStatus,
    Splash,
    LoginWrapper,
    ProfileSelect,
    Intro,
    FirstConcept,
    CreateSafetyPlan,
    Return,
    UpdateSuccess,
    PlayerFeedback,
    Fork,
    Inbox,
    Chapter,
    KeyConcepts,
    TheHeader,
    Outro,
  },
  data() {
    return {
      chapter: null,
      modalOpen: false,
      modalHideCallback: null,
      state: "startup",
      doTransition: true,

      isOnline: Connection.isOnline,
      updateReady: Data.hasUpdate || ServiceWorker.waiting,
      langReady: false,

      // menu
      menuOpen: false,
      menuIconPulse: false,
      menuIconShow: false,

      // auth
      guestId: null,
      guestStatus: "unknown",
      guestPollTimeout: null,
      authReady: false,
      loggedIn: false,
    };
  },
  computed: {
    isMuteButtonShown() {
      // show mute button after login and before menu is available
      return this.menuOpen || this.state === "login";
    },
    isControlsReady() {
      // global app controls available when app is interactive and language data is ready
      // MuteButton, NavPrompt, MenuIcon
      return (
        this.langReady &&
        !(
          this.state === "black" ||
          this.state === "startup" ||
          this.state === "offline" ||
          this.state === "splash"
        )
      );
    },
    isAfterLogin() {
      return !(
        this.state === "black" ||
        this.state === "startup" ||
        this.state === "offline" ||
        this.state === "splash" ||
        this.state === "login" ||
        this.state === "profile"
      );
    },
    isLangReady() {
      return this.langReady;
    },
    menuPulse() {
      console.log("pulse icon?", this.menuIconPulse, this.isOnline);
      return this.menuIconPulse && this.isOnline;
    },
  },
  methods: {
    onKey(e) {
      // ESC - close ui layers in sequence
      if (e.key === "Escape") {
        // close modal first
        if (this.modalOpen) {
          this.modalHide();

          // close menu second
        } else if (this.menuOpen) {
          this.menuOpen = false;
          AudioManager.play("ui-menu-close");
          Scroll.start();
        }
        Input.keyboardMode();
      }
    },

    shouldShowFeedback() {
      return !UserData.feedback && testCompletionCount(UserData.progress, 3);
    },

    resetInboxPosition() {
      UserData.setChapterIndex(
        firstIncompleteChapter(Data.data.chapters, UserData.progress)
      );
    },

    // STATES

    async afterLogin(cognitoUser) {
      UserData.clear();
      const syncPromise = UserData.sync();

      this.setLoggedIn(cognitoUser);

      // hold black screen until sync
      this.state = "black";
      await syncPromise;

      // profiles - user must select profile
      if (SessionData.hasProfiles) {
        this.state = "profile";
      }

      // no profiles - user starts playing
      else {
        this.loadUserSave();
        this.resetInboxPosition();
      }
    },

    // PROFILES

    // profile selected
    setProfile(profile) {
      UserData.setActiveProfile(profile);

      UserData.loadSave();
      this.resetInboxPosition();

      if (this.shouldShowFeedback()) {
        this.state = "feedback";
        Background.setColors(defaultGradient);
      } else if (UserData.intro) {
        this.state = "return";
        Background.setColors(defaultGradient);
      } else {
        this.state = "intro";
      }
    },

    // exit profile via menu
    menuProfile(promise) {
      this.menuOpen = false;
      this.doTransition = false;
      this.state = "black";
      Background.fadeToBlack();

      this.menuTimeout = setTimeout(async () => {
        this.doTransition = true;
        Scroll.start();

        await promise;

        this.state = "profile";
      }, MENU_DELAY);
    },

    toFirstConcept() {
      this.state = "first-concept";
      Background.transitionDown();
    },
    toSafetyPlan() {
      this.state = "safety-plan";
      Background.transitionDown();
    },
    async toFork() {
      // get latest save data
      const syncPromise = UserData.sync();

      // animate out while waiting...
      Background.transitionDown();
      this.state = "color";
      this.setHeaderDisplay(false);

      await syncPromise;

      // enter new state
      this.state = "fork";
    },
    toKeyConcepts() {
      this.state = "concepts";
      Background.transitionDown();
    },
    async toInbox() {
      // get latest save data
      const syncPromise = UserData.sync();

      // animate while waiting...
      Background.transitionDown();
      this.state = "color";

      await syncPromise;

      // enter new state
      this.state = "inbox";
    },
    toChapter(chapter) {
      this.chapter = chapter;
      this.state = "chapter";
    },

    afterChapter() {
      // outro - if not seen and all chapters completed
      if (!UserData.outro && allChaptersComplete(UserData.progress)) {
        this.state = "outro";
        Background.setColors(defaultGradient);
        Background.transitionDown();
      }

      // feedback - if not seen and 3 chapters completed
      else if (this.shouldShowFeedback()) {
        this.state = "feedback";
        Background.setColors(defaultGradient);
        Background.transitionDown();
      }

      // inbox - default
      else {
        this.toInbox();
      }
    },

    // HEADER

    setHeaderDisplay(bool) {
      this.$refs.header.setDisplay(bool);
    },
    setHeaderTitle(title, reverse = false) {
      this.$refs.header.setTitle(title, reverse);
    },
    setHeaderProgress(progress, total, pattern) {
      this.$refs.header.setProgress(progress, total, pattern);
    },
    setHeaderProgressStyle(style) {
      this.$refs.header.setProgressStyle(style);
    },
    saveHeaderState() {
      this.$refs.header.saveState();
    },
    restoreHeaderState() {
      this.$refs.header.restoreState();
    },

    // SHAPES

    shapeState(state) {
      if (this.$refs.shapes) this.$refs.shapes.setState(state);
    },
    shapeTarget(element) {
      if (this.$refs.shapes) this.$refs.shapes.setTarget(element);
    },
    shapeIn() {
      if (this.$refs.shapes) this.$refs.shapes.in();
    },
    shapeOut() {
      if (this.$refs.shapes) this.$refs.shapes.out();
    },
    shapeNext(options) {
      if (this.$refs.shapes) this.$refs.shapes.next(options);
    },

    // PAGE NAV
    // the nav prompt needs to be able to trigger the page transition

    setPage(page) {
      this.page = page;
    },
    nextPage() {
      if (this.page) {
        this.page.toNextPage();
        this.page = null;
      }
    },

    // MENU ICON

    iconStartPulse() {
      // only pulse if the menu is closed
      if (!this.menuOpen) {
        this.menuIconPulse = true;
      }
    },
    iconStopPulse() {
      this.menuIconPulse = false;
    },
    iconShow() {
      this.menuIconShow = true;
    },
    iconHide() {
      this.menuIconShow = false;
    },
    pulseIfUpdate() {
      if (this.updateReady) {
        this.iconStartPulse();
      }
    },

    // MENU

    toggleMenu() {
      if (this.menuOpen) {
        AudioManager.play("ui-menu-close");
        this.menuOpen = false;
        Scroll.start();
      } else {
        this.menuOpen = true;
        Scroll.stop();
        this.iconStopPulse();
      }
    },

    // prompt

    promptValue(v) {
      if (this.$refs.prompt) this.$refs.prompt.setValue(v);
    },
    promptShow() {
      if (this.$refs.prompt) this.$refs.prompt.show();
    },
    promptSetEnd(bool) {
      if (this.$refs.prompt) this.$refs.prompt.setEnd(bool);
    },
    promptReset() {
      if (this.$refs.prompt) this.$refs.prompt.reset();
    },

    // activity prompt

    showSwipePrompt() {
      if (this.$refs.swipeActivityPrompt) {
        this.$refs.swipeActivityPrompt.show();
      }
    },
    hideSwipePrompt() {
      if (this.$refs.swipeActivityPrompt) {
        this.$refs.swipeActivityPrompt.hide();
      }
    },

    // modal

    modalShow(callback) {
      this.modalOpen = true;
      this.modalHideCallback = callback;
    },
    modalHide() {
      this.modalOpen = false;
      if (this.modalHideCallback) {
        this.modalHideCallback();
        this.modalHideCallback = null;
      }
    },

    // GUEST MODE

    // pass code to manifest so it can be used in pwa
    async checkGuest() {
      clearTimeout(this.guestPollTimeout);

      // only check if status unknown
      if (this.guestStatus !== "unknown") return;

      // get the current guest code

      // prefer new code in url
      let guestCode = window.location.pathname.replaceAll("/", "");

      if (guestCode && guestCode !== PersistentData.guestCode) {
        PersistentData.setGuestCode(guestCode);
      } else {
        guestCode = PersistentData.guestCode;
      }

      // if no code in url or local data - return nulls
      if (!guestCode) {
        this.guestStatus = null;
        return;
      }

      // send code to api for validation
      try {
        const groupId = await API.get(
          "PublicAPI",
          "/app/check-guest?code=" + guestCode
        );

        // accepted
        this.guestStatus = "accepted";
        this.guestId = groupId;
      } catch (error) {
        // rejected
        if (error?.response?.status === 404) {
          this.guestStatus = "rejected";
          console.error("getGuest() code invalid", error);
        }

        // unknown
        else {
          this.guestStatus = "unknown";
          console.error("getGuest() unknown error", error);

          // try again
          this.guestPollTimeout = setTimeout(
            this.checkGuest,
            GUEST_POLL_INTERVAL
          );
        }
      }
    },
    startGuestMode(groupId) {
      UserData.setGuestMode(true);
      SessionData.setIsFacilitated(true);
      Analytics.trackEvent("play_as_guest", { group: groupId });
      Insert.setUsername(SessionData.lang.Login.guest_email_placeholder);
      this.state = "intro";
    },

    // CHOOSE START SCREEN

    // start point - after startup, after returning online
    toStartPoint() {
      // login - user not authenticated, need to log in
      if (!this.loggedIn) {
        this.state = "login";
        Background.fadeToBlack();
      }

      // profiles
      else if (SessionData.hasProfiles) {
        if (UserData.activeProfile) {
          this.loadProfileSave();
        } else {
          this.state = "profile";
        }
      }

      // non-profiles
      else {
        this.loadUserSave();
      }
    },
    loadUserSave() {
      UserData.loadSave();

      if (this.shouldShowFeedback()) {
        this.state = "feedback";
        Background.setColors(defaultGradient);
      } else if (UserData.intro) {
        this.state = "return";
        Background.setColors(defaultGradient);
      } else {
        this.state = "intro";
        Background.fadeToBlack();
      }
    },
    loadProfileSave() {
      UserData.loadSave();

      // catch case where profile deleted
      if (!UserData.currentSave) {
        this.state = "profile";
        Background.fadeToBlack();
      } else if (this.shouldShowFeedback()) {
        this.state = "feedback";
        Background.setColors(defaultGradient);
      } else if (UserData.intro) {
        this.state = "return";
        Background.setColors(defaultGradient);
      } else {
        this.state = "profile";
        Background.fadeToBlack();
      }
    },

    // STARTUP

    afterStartup() {
      Data.onUpdateNeeded(this.onUpdateNeeded); // force urgent update
      Data.onUpdateReady(this.onUpdateReady); // prompt user to update
      Data.onUpdateComplete(this.onUpdateComplete); // success!

      ServiceWorker.onWaiting(this.swWaiting); // new code downloaded
      ServiceWorker.onLoad(this.swLoad); // code ready - need reload

      // no offline mode for users without storage
      if (!OfflineMedia.available) {
        Connection.addOnlineEvent(this.offlineStart);
        Connection.addOfflineEvent(this.offlineEnd);

        if (Connection.isOffline) {
          this.state = "offline";
          return;
        }
      }

      this.toStartPoint();
      this.pulseIfUpdate();
    },

    // ONLINE STATUS

    onOnline() {
      this.isOnline = true;
      this.checkGuest();
    },
    onOffline() {
      this.isOnline = false;
    },

    // OFFLINE + NO STORAGE
    // no storage means no media
    // show offline screen

    offlineStart() {
      this.state = "offline";
      Background.fadeToBlack();
      Background.transitionDown();
    },
    offlineEnd() {
      this.toStartPoint();
      this.pulseIfUpdate();
    },

    // MANUAL UPDATE
    // after startup

    // urgent updates are forced...
    onUpdateNeeded() {
      this.state = "splash";
    },

    // Data.js has new content ready
    onUpdateReady() {
      console.log("App.onUpdateReady()");

      // update now if urgent
      if (Data.needsUpdate || PersistentData.updating) {
        Data.update();
        this.state = "splash";
      }

      // prompt user to update
      else {
        this.setUpdateReady();
        Data.resume(); // continue polling for even newer content
      }
    },
    setUpdateReady() {
      this.updateReady = true;
      this.iconStartPulse();
    },

    //manually triggered from menu
    menuUpdate() {
      console.log("MANUAL UPDATE");

      this.menuOpen = false;
      this.doTransition = false;
      this.state = "black";

      // update service worker
      if (ServiceWorker.waiting) {
        Background.fadeToBlack();
        this.menuTimeout = setTimeout(ServiceWorker.update, MENU_DELAY);
      }

      // update content
      else {
        Background.setColors(defaultGradient);
        Background.dim();
        this.menuTimeout = setTimeout(() => {
          Data.update();
          this.state = "splash";
          this.doTransition = true;
          Scroll.start();
        }, MENU_DELAY);
      }
    },
    onUpdateComplete() {
      this.$refs.splash.fadeOut();
    },
    onLangReady() {
      this.langReady = true;
    },
    afterSplash() {
      // resume polling for updates
      this.updateReady = false;
      Data.resume();

      if (!this.loggedIn) {
        this.state = "login";
      } else {
        this.state = "updated";
        Background.setColors(defaultGradient);
      }

      Background.transitionDown();
    },

    // SERVICE WORKER UPDATE

    swWaiting() {
      console.log("App.swWaiting()");
      this.setUpdateReady();
      ServiceWorker.resume(); // accept even newer code
    },
    swLoad() {
      ServiceWorker.reload();
    },

    // LANGUAGE

    changeLanguage(newLanguage) {
      Data.changeLanguage(newLanguage);
      this.state = "splash";
    },

    // STRTUP AUTH

    async authFailed() {
      console.log("-- AUTH -- FAIL");
      this.loggedIn = false;
      this.authFinished();
    },
    async authSuccess(cognitoUser) {
      console.log("-- AUTH -- OKAY");
      this.setLoggedIn(cognitoUser);
      this.authFinished();
    },
    authFinished() {
      this.authReady = true; // let startup know user is authenticated

      // log out when lang changes
      // TODO - refactor me please
      Data.onLangReady(this.onLangReady);
      Data.onLangError(this.signout);
    },

    // LOGIN STATE

    setLoggedIn(cognitoUser) {
      console.log("App.setLoggedIn()", cognitoUser);
      this.loggedIn = true;

      Analytics.trackSession(cognitoUser);

      SessionData.setIsFacilitated(
        cognitoUser.attributes["custom:isFacilitated"] === "True"
      );
      SessionData.setHasProfiles(
        cognitoUser.attributes["custom:hasProfiles"] === "True"
      );
      Insert.setUsername(cognitoUser.attributes.email);

      UserApi.start();
    },

    // LOGOUT SEQUENCE

    async signout() {
      console.log("signout");

      if (this.loggedIn) {
        this.loggedIn = false;
        await UserApi.stop(); // await or abort pending cloud saves
        await Auth.signOut();
        Analytics.trackEvent("logout");
        Analytics.destorySession();
      }
    },
    menuLogout(promise) {
      this.menuOpen = false;
      this.doTransition = false;
      this.state = "black";
      Background.fadeToBlack();

      this.menuTimeout = setTimeout(async () => {
        this.doTransition = true;
        Scroll.start();

        await promise;

        this.state = "login";
      }, MENU_DELAY);
    },
    async profileLogout() {
      const signoutPromise = this.signout();

      this.iconHide();
      Background.fadeToBlack();

      this.state = "black";

      await signoutPromise;

      this.state = "login";
    },
  },

  created() {
    Auth.currentAuthenticatedUser().then(this.authSuccess, this.authFailed);

    // monitor online status
    // - check guest when online
    // - inform startup sequence of online status
    Connection.addOnlineEvent(this.onOnline);
    Connection.addOfflineEvent(this.onOffline);

    if (Connection.isOnline) {
      this.checkGuest();
    }

    Background.initColors();
  },
  mounted() {
    window.addEventListener("keydown", this.onKey);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKey);

    clearTimeout(this.menuTimeout);
    clearTimeout(this.guestPollTimeout);
    cancelAnimationFrame(this.frameDelay);

    // stop modules
    // these will clear any callbacks internally
    Data.stop();
    Connection.stop();
    OfflineMedia.stop();
    UserApi.stop();
    ServiceWorker.stop();
  },
};
</script>

<style lang="scss">
// styles that need to be set AFTER all other styles
// TODO

// default space between content in pages
.page-content-margin {
  margin-top: $content-margin;

  &:first-child {
    margin-top: 0;
  }
}

.card-margin {
  margin: $content-radius 0 0;
}
</style>
